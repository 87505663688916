<template>
    <div class="main-page-container" :class="{mobile: isMobile}">
        <Canvas class="canvas-container" />
        <div class="main-page-wrapper">
            <div class="topic" id="topic_0" >
                <GradientWheel class="try-screen"/>
                <div class="topic-content">
                    <div class="logo" v-html="$translation.logoSVG">
                    </div>
                    <h2 v-html="$translation.platformTitle"></h2>
                    <div class="try-out-container" @click="tryOutButton">
                        <div class="button try-out">{{$translation.tryOutNow}}</div>
                        <div class="arrow-container">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="130.924" height="47.056" viewBox="0 0 130.924 47.056">
                                 <path id="Path_7508" data-name="Path 7508" d="M788.649,793.728l58.619,34.415,58.619-34.415" transform="translate(-781.805 -786.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="10"/>
                                </svg>
                            </span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="112.317" height="41.594" viewBox="0 0 112.317 41.594">
                                <path id="Path_7509" data-name="Path 7509" d="M788.649,793.728l49.315,28.953,49.315-28.953" transform="translate(-781.805 -786.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="10"/>
                                </svg>
                            </span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="94.199" height="36.276" viewBox="0 0 94.199 36.276">
                                <path id="Path_7510" data-name="Path 7510" d="M788.649,793.728l40.256,23.635,40.256-23.635" transform="translate(-781.805 -786.885)" fill="none" stroke="#28345c" stroke-linecap="round" stroke-width="10"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="topic text" id="topic_1" >
                <div class="topic-row">
                    <div class="topic-col left">
                        <span><h3> {{$translation.whatIsElklase}}</h3></span>
                        <p class="about" v-html="$translation.about1"></p>
                        <p class="about" v-html="$translation.about2"></p>
                    </div>
                    <div class="topic-col">
                        <Card card-type="login" :currentTopic="currentTopic" @jumpTopic="jumpTopicScrollbar"/>
                    </div>
                </div>
            </div>
            <div class="topic" id="topic_2">
                <div class="topic-row py-0">
                   <span class="topic-title"><h3> {{$translation.topic2_title}}</h3></span>
                </div>
                <div class="topic-row grid col-3">
                    <div class="grid-col">
                        <div class="list-item" v-for="(use, use_id) in usedFor.slice(0, 4)" :key="use_id">
                            <div class="list-item-row">
                                <div class="list-item-icon">
                                    <img :src="require(`@/assets/${use.icon}`)" alt="">
                                </div>
                                <p>{{use.description}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid-col">
                        <div class="list-item" v-for="(use, use_id) in usedFor.slice(4, 8)" :key="use_id">
                            <div class="list-item-row">
                                <div class="list-item-icon">
                                     <img :src="require(`@/assets/${use.icon}`)" alt="">
                                </div>
                                <p>{{use.description}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid-col">
                        <div class="list-item" v-for="(use, use_id) in usedFor.slice(8, 12)" :key="use_id">
                            <div class="list-item-row">
                                <div class="list-item-icon">
                                     <img :src="require(`@/assets/${use.icon}`)" alt="">
                                </div>
                                <p>{{use.description}}</p>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div class="topic scrollable" id="topic_3" :ref="`scrollable-3`" >
                <div class="topic-row">
                   <span class="topic-title short"><h3> {{$translation.topic3_title}}</h3></span>
                </div>
                <div class="topic-row p-0 w-60">
                     <div class="flex-col lg-list">
                        <div class="list-item content" v-for="(content_item, content_index) in content" :key="content_index">
                            <div class="item">
                                <div class="list-item-icon">
                                    <img :src="require(`@/assets/${content_item.icon}`)" alt="">
                                </div>
                                <p>{{content_item.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="topic" id="topic_4" >
                <div class="topic-row py-0">
                   <span class="topic-title"><h3> {{$translation.topic4_title}}</h3></span>
                </div>
                <div class="topic-row py-0">
                    <div class="social-media-row">
                        <div class="media-container" v-for="(media, media_index) in socialMedia" :key="media_index">
                            <div class="list-item-icon">
                                <img :src="require(`@/assets/${$store.state.windowWidth <= 768 ? media.icon_mobile : media.icon}`)" alt="">
                            </div>
                            <p>{{media.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="topic" id="topic_5" >
                <div class="topic-row">
                    <div class="topic-col left">
                        <span><h3>{{$translation.topic5_try_out1}}</h3></span>
                        <p>{{$translation.topic5_try_out2}}</p>
                    </div>
                     <div class="topic-col">
                         <Card card-type="registration" :currentTopic="currentTopic" @jumpTopic="jumpTopicScrollbar"/>
                     </div>
                    <GradientWheel v-if="$store.state.windowWidth > 768" class="registration-gradient"/>
                </div>
            </div>
            <div class="topic" id="topic_6">
                <Footer @privacyClicked="privacyPolicyOpen = true"/>
            </div>

        </div>
        <GoToTop :currentTopic="currentTopic" @scrollToTop="scrollToTop"/>
        <ScrollBar 
            v-if="$store.state.windowWidth > 768"  
            :currentTopic="currentTopic" 
            :isTransitioning="isTransitioning" 
            @scrollTopic="scrollTopicScrollbar" 
            @jumpTopic="jumpTopicScrollbar" 
        />
        <PrivacyPolicy v-if="privacyPolicyOpen" @closeModal="privacyPolicyOpen = false"/>
    </div>
</template>

<script>
import Canvas from '@/components/Canvas.vue'
import GradientWheel from '@/components/GradientWheel.vue'
import ScrollBar from '@/components/ScrollBar.vue'
import Card from '@/components/Card.vue'
import Footer from '@/components/Footer.vue'
import GoToTop from '@/components/GoToTop.vue'
import VueScrollTo from "vue-scrollto";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";

export default {
    name: "Main",
    components:{
    Canvas,
    GradientWheel,
    Card,
    Footer,
    GoToTop,
    ScrollBar,
    PrivacyPolicy
},
    data(){
        return{
            showContent: 0,
            currentTopic: 0,
            isTransitioning:false,
            isTransitioningMulti: false,
            topics:[
                {id: 0},
                {id: 1},
                {id: 2},
                {id: 3},
                {id: 4},
                {id: 5},
                {id: 6},
            ],
            scrollCount: 0,
            scrollLimit: 5,
            firstPageIndex: 0,
            lastPageIndex: 6,
            usedFor: [
                {icon: 'use_icons/map.svg', description: this.$translation.usedFor.d1},
                {icon: 'use_icons/creative_book.svg', description: this.$translation.usedFor.d2},
                {icon: 'use_icons/location.svg', description: this.$translation.usedFor.d3},
                {icon: 'use_icons/idea.svg', description: this.$translation.usedFor.d4},
                {icon: 'use_icons/magic_touch.svg', description: this.$translation.usedFor.d5},
                {icon: 'use_icons/structured_books.svg', description: this.$translation.usedFor.d6},
                {icon: 'use_icons/pencil.svg', description: this.$translation.usedFor.d7},
                {icon: 'use_icons/unlimited_paper.svg', description: this.$translation.usedFor.d8},
                {icon: 'use_icons/reusability.svg', description: this.$translation.usedFor.d9},
                {icon: 'use_icons/magical_brainstorm.svg', description: this.$translation.usedFor.d10},
                {icon: 'use_icons/control_authenticity.svg', description: this.$translation.usedFor.d11},
                {icon: 'use_icons/observe.svg', description: this.$translation.usedFor.d12},
            ],
            content:[
                 {icon: 'content_icons/library.svg', description: this.$translation.content.d1},
                 {icon: 'content_icons/anotation.svg', description: this.$translation.content.d2},
                 {icon: 'content_icons/whiteboard.svg', description: this.$translation.content.d3},
                 {icon: 'content_icons/math.svg', description: this.$translation.content.d4},
                 {icon: 'content_icons/writting_task.svg', description: this.$translation.content.d5},
                 {icon: 'content_icons/website_upload.svg', description: this.$translation.content.d6},
                 {icon: 'content_icons/social_media_embed.svg', description: this.$translation.content.d7},
                 {icon: 'content_icons/url_embed.svg', description: this.$translation.content.d8},
                 {icon: 'content_icons/media_embed.svg', description: this.$translation.content.d9},
                 {icon: 'content_icons/audio_record.svg', description: this.$translation.content.d10},
                 {icon: 'content_icons/interactive_tasks.svg', description: this.$translation.content.d11},
                 {icon: 'content_icons/user_upload_task.svg', description: this.$translation.content.d12},
                 {icon: 'content_icons/survey.svg', description: this.$translation.content.d13},
                 {icon: 'content_icons/test_types.svg', description: this.$translation.content.d14},
            ],
            socialMedia:[
                 {icon: 'social_icons/facebook.svg', icon_mobile: 'social_icons/mobile/facebook.svg', description: this.$translation.social.d1},
                 {icon: 'social_icons/instagram.svg', icon_mobile: 'social_icons/mobile/instagram.svg', description: this.$translation.social.d2},
                 {icon: 'social_icons/twitter.svg', icon_mobile: 'social_icons/mobile/twitter.svg', description: this.$translation.social.d3},
                 {icon: 'social_icons/tiktok.svg', icon_mobile: 'social_icons/mobile/tiktok.svg', description: this.$translation.social.d4},
                 {icon: 'social_icons/youtube.svg', icon_mobile: 'social_icons/mobile/youtube.svg', description: this.$translation.social.d5},
                 {icon: 'social_icons/vimeo.svg', icon_mobile: 'social_icons/mobile/vimeo.svg', description: this.$translation.social.d6},
                 {icon: 'social_icons/spotify.svg', icon_mobile: 'social_icons/mobile/spotify.svg', description: this.$translation.social.d7},
                 {icon: 'social_icons/soundcloud.svg', icon_mobile: 'social_icons/mobile/soundcloud.svg', description: this.$translation.social.d8},
                 {icon: 'social_icons/wordwall.svg', icon_mobile: 'social_icons/mobile/wordwall.svg', description: this.$translation.social.d9},
                 {icon: 'social_icons/learning_apps.svg', icon_mobile: 'social_icons/mobile/learning_apps.svg', description: this.$translation.social.d10},
                //  {icon: 'social_icons/zoom.svg', icon_mobile: 'social_icons/mobile/zoom.svg', description: this.$translation.social.d11},
                //  {icon: 'social_icons/ms_teams.svg', icon_mobile: 'social_icons/mobile/ms_teams.svg', description: this.$translation.social.d12},
                 {icon: 'social_icons/explore.svg', icon_mobile: 'social_icons/mobile/explore.svg', description: this.$translation.social.d13},
            ],
            isMobile: false,
            isListeningForWheel: false,
            unlockTopic: false,
            forgotPasswordModalOpen: false,
            privacyPolicyOpen: false,
            isScrolling:false,
        }
    },
    watch:{
        isTransitioning(){
            if(this.isTransitioning){
                if(!this.isMobile){
                    let element = document.getElementById('topic_'+this.showContent)
                    VueScrollTo.scrollTo(element, {duration: 300, cancelable: false});
                    setTimeout(()=>{
                        this.isTransitioning = false
                    },500)  
                }
                else{
                    document.getElementById('topic_'+this.showContent).scrollIntoView({
                        behavior: "smooth"
                    });
                }
            }
        },
        showContent(){
            setTimeout(()=>{
       
            },50);
        },
        '$store.state.windowWidth':{
            immediate: true,
            handler(newWidth){
                this.isMobile = newWidth <= 768;
                if(!this.isMobile){
                    if(!this.isListeningForWheel){
                        window.addEventListener('wheel', this.scrollPage)
                        this.isListeningForWheel = true;
                    }
                }
            }
        }
    },
    mounted(){
        setTimeout(()=>{
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
                });
        },400)
        
    },
    beforeDestroy(){
        window.removeEventListener('wheel',  this.scrollPage);
    },
    methods:{
        scrollPage(e){
            if(!this.isTransitioning){
                if(this.currentTopic != 3){
                    this.scrollCount++;
                    if(e.deltaY > 0){
                        if(this.showContent < this.lastPageIndex){
                            this.showContent++;
                            this.currentTopic++;
                            this.isTransitioning=true;
                        }
                    }
                    else if(e.deltaY < 0){
                        if(this.showContent > this.firstPageIndex){
                            this.showContent--;
                            this.currentTopic--;
                            this.isTransitioning=true;
                        }
                    }
                }
                else{
                    var element = this.$refs['scrollable-3'];
                    if(Array.isArray(element)) element = element[0];
                    if(element == undefined) return;
                    element.scroll({ top: element.scrollTop + (e.deltaY > 0 ? 300 : -300), left: 0,  behavior: 'smooth'});
                    this.scrollCount++;
                    if (Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight+5)
                    {
                        if(e.deltaY > 0){
                            if(this.showContent < this.lastPageIndex){
                                this.showContent++;
                                this.currentTopic++;
                                this.isTransitioning=true;
                            }
                        }
                    }
                    if(element.scrollTop == 0){

                        if(e.deltaY < 0){
                            if(this.showContent > this.firstPageIndex){
                                this.showContent--;
                                this.currentTopic--;
                                this.isTransitioning=true;
                            }
                        }
                    }
                }
            }
        },
        scrollTopicScrollbar(direction){
            if(direction == 1){
                if(this.showContent < this.lastPageIndex){
                    this.showContent++;
                    this.currentTopic++;
                    this.isTransitioning = true;
                }
            }
            else{
                if(this.showContent > this.firstPageIndex){
                    this.showContent--;
                    this.currentTopic--;
                    this.isTransitioning = true;
                }
            }
        },
        scrollToTop(){
            this.showContent = 0;
            this.currentTopic = 0;
            this.isTransitioning = true;
            this.isTransitioningMulti = true;
            if(this.$store.state.windowWidth <= 768){
                let elem = document.getElementsByClassName('main-page-wrapper')[0];
                elem.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
                });
            }
        },
        tryOutButton(){
            this.showContent = 1
            this.currentTopic = 1;
            let element = document.getElementById('topic_1')
            VueScrollTo.scrollTo(element, {duration: 300, cancelable: false});
        },
        jumpTopicScrollbar(topic){
            this.showContent = topic;
            this.currentTopic = topic;
            let element = document.getElementById('topic_'+this.showContent)
            this.scrollFunction = VueScrollTo.scrollTo(element, {
            duration: 300, 
            cancelable: true,
            
            });
         
        },



    }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_button.scss";

.canvas-container{
    z-index: -1;
}
    .main-page-container{
        display: flex;
        width: 100%;
        
        .main-page-wrapper{
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            .topic{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-height: 100vh;
                height: 100vh;
                overflow: hidden;
          
                .topic-content{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 5rem;
                    width: 50%;
                    h2{
                        line-height: 1.7;
                        font-weight: 900;
                        font-size: clamp(16px, 2.5vw, 4vw);
                        span{
                            font-size: clamp(12px, 3.5vw, 3vw);
                        }
                    }
                    .try-out-container{
                        width: 75%;
                        margin: auto;
                    }
                    .arrow-container{
                        display: flex;
                        flex-direction: column;
                        margin-top: 1.5rem;
                        span{
                            opacity: 0;
                        }
                        span:first-child{
                            animation: arrow-animation 5s infinite;
                            animation-delay: 0.5s;
                            animation-direction: alternate;

                            svg{
                                width: 7rem;
                            }
                        }
                        span:nth-child(2){
                            animation: arrow-animation 5s infinite;
                            animation-delay: 1s;
                            animation-direction: alternate;
                            svg{
                                margin-top: -0.5rem;
                                width: 6rem;
                            }
                        }
                        span:nth-child(3){
                            animation: arrow-animation 5s infinite;
                            animation-delay: 1.5s;
                            animation-direction: alternate;
                            svg{
                                margin-top: -0.5rem;
                                width: 5rem;
                            }
                        }
                    }
                }
                .topic-row{
                    display: flex;
                    flex-direction: row;
                    width: calc(100% - 10rem);
                    padding: 0.5rem 5rem;
                    .topic-col{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 5rem 0;
                        &.left{
                            text-align: left;
                            margin-top: 3rem;
                            padding-right: 13vw;
                        }
                        .about{
                            position: relative;
                            line-height: 1.7;
                            font-size: clamp(16px, 1.4vw, 2vw);
                            b{
                                font-weight: 900;
                            }
                            &::after{
                                position: absolute;
                                content: "";
                                bottom: -1rem;
                                height: 0.4rem;
                                width: 2rem;
                                left: 0;
                                background: #28345C;

                            }
                        }
                        p{
                            font-size: clamp(0.5em, 1.3vw, 2em);
                        }
                    }
                    .flex-col{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        &.lg-list{
                            margin-bottom: 2rem;
                        }
                    }
                    &.grid{
                        display: grid;
                        gap: 4rem;
                        &.col-3{
                            grid-template-columns: 1fr 1fr 1fr;
                        }
                    }
                    .grid-col{
                        position: relative;
                        &::after{
                            position: absolute;
                            content: "";
                            bottom: 0;
                            height: 65%;
                            width: 0.2rem;
                            right: 0;
                            top: 0;
                            margin: auto;
                            background: #28345C;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                    }
                    .list-item{
                        
                        .list-item-row{
                            display: flex;
                            margin-bottom: 2rem;
                            .list-item-icon{
                                
                                background: linear-gradient(180deg, rgba(181,195,241,1) 78%, rgba(203,215,255,1) 100%);
                                height: 4vw;
                                width: 4vw;
                                min-height: 4vw;
                                min-width: 4vw;
                                max-height: 4vw;
                                max-width: 4vw;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                border-radius: 0.75vw;
                                box-shadow: 0px 2px 9px -3px #b5b5b5;
                                img{
                                    height: 60%;
                                    width: 60%;
                                    transition: transform 0.3s;
                                }
                            }
                            p{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin: 0;
                                margin-left: 1rem;
                                text-align: left;
                            }
                            &:hover{
                                img{
                                    transform: scale(1.1);
                                }
                            }
                        }
                        &:last-child{
                            .list-item-row{
                                margin-bottom: 0;
                            }
                        }
                        &.content{
                            display: flex;
                            flex-basis: 20%;
                            height: 11rem;
                            margin: 2rem;
                        }
                        .item{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            background: #FAFAFA;
                            border-radius: 0.5rem;
                            padding: 0.5rem;
                            font-weight: 700;
                            width: 100%;
                            border:1px solid #F1F1F1;
                            box-shadow: 0px 0px 5px 0px #e3e3e3;
                            transition: transform 0.3s;
                            img{
                                display: flex;
                                width: 100%;
                                height: 5.5rem;
                            }
                            p{
                                font-size: clamp(0.5em, 1vw, 1em);
                                margin-bottom: 0;
                            }
                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
               
            }
        }
    }

   .input-container{
       display: flex;
       flex-direction: column;
       .forgot-password{
           display: flex;
           cursor: pointer;
           margin-left: 2rem;
           font-size: clamp(12px, 0.5vw, 1vw);
       }
   }
   input{
       border-radius: 2rem;
       padding: 0.75rem 2rem;
       border: none;
       background: white;
       margin: 0.5rem 0;
       box-shadow: 0px 0.1rem 0.2rem 0.1rem #99999975;
    font-family: var(--main-site-font);

       &::placeholder{
           color:#24345f71
       }
   }
   h3{
        display: inline-flex;
        border-bottom: 0.35rem solid #A9B9EE;
        padding-bottom: 0.35rem;
        font-weight: 900;
        font-size: clamp(1em, 1.7vw, 3em);
   }
   .social-media-row{
        position: relative;
        display: flex;
        background: linear-gradient(0deg, rgba(169,185,238,1) 0%, rgb(219, 225, 247) 100%);
        border-radius: 0.5rem;
        padding: 1.5rem 4rem;
        width: 100%;
        box-shadow: 0 3px 11px -5px #65729d;
        &::before{
            position: absolute;
            content: '';
            left: -4px;
            top: -4px;
            height: calc(100% + 8px);
            width: calc(100% + 8px);
            border-radius: 0.5rem;
            background: linear-gradient(0deg, rgba(169,185,238,1) 0%, rgb(219, 225, 247) 100%);
            opacity: 0.25;
        }
   }
   .logo{
       svg{
           display: flex;
            width: 100%;
       }
   }
   .media-container{
        display: flex;
        flex-direction: column;
        width: 90px;
        width: 10%;
        color: white;
        z-index: 3;
        .list-item-icon{
            margin: auto;
            width: 4rem;
            img{
                width: 100%;
            }
        }
        p{
            margin: 0;
            margin-top: 0.5rem;
            font-size: clamp(0.5em, 0.9vw, 1em);
        }
   }
   .registration-gradient{
       position: absolute;
       top: 17%;
       left: 0;
       &::after{
            position: absolute;
            content: "";
            top: 43%;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, white 92%, rgba(255, 255, 255, 0) 100%);
       }
   }
    .py-0{
        padding-top: 0 !important;
       padding-bottom: 0 !important;
   }
   .p-0{
       padding: 0 !important;
   }
   .w-60{
        width: 60% !important;
   }
   .scrollable{
        overflow: auto !important;
        justify-content: flex-start !important;
   }

    @media (max-width: 768px){
        .main-page-container{
            height: 100vh;
            flex-direction: column;
            .main-page-wrapper{
                display: block;
                height: 100%;
                // overflow: auto;
                .topic{
                    min-height: 100%;
                    justify-content: flex-start;
                    overflow-x: hidden !important;
                    max-height: initial;
                    height: initial;
                    margin: 5rem 0;
                    .topic-content{
                        width: 100%;
                        padding: 0 2rem; 
                        h2{
                            font-size: clamp(16px, 5.5vw, 3em);
                            span{
                                font-size: clamp(12px, 6.5vw, 8vw);
                            }
                        }
                        .try-out-container{
                            width: 100%;
                            margin: auto;
                        }
                        .arrow-container{
                            span{
                                &:first-child{
                                    svg{
                                        width: 20vw;
                                    }
                                }
                                &:nth-child(2){
                                    svg{
                                        margin-top: -5vw;
                                        width: 18vw;
                                    }
                                }
                                &:last-child{
                                    svg{
                                        margin-top: -4vw;
                                        width: 16vw;
                                    }
                                }
                            }
                        }
                    }
                    .topic-row{
                        width: 100%;
                        padding: 0;
                        flex-direction: column;
                        .topic-col{
                            padding: 0 2rem;
                            &.left{
                                margin-top: 0;
                                padding: 0 2rem;
                            }
                            .about{
                                font-size: clamp(20px, 5.4vw, 2vw);
                            }
                            p{
                                font-size: clamp(0.5em, 5.5vw, 2em);
                            }
                        }
                        &.grid{
                            &.col-3{
                                grid-template-columns: 1fr;
                                gap: 2rem;
                                padding: 1rem 2rem
                            }
                        }
                        .grid-col{
                            &::after{
                                display: none;
                            }
                        }
                        .list-item{
                            .list-item-row{
                                .list-item-icon{
                                    min-width: 3rem;
                                    min-height: 3rem;
                                    border-radius: 0.5rem;
                                }
                            } 
                            .item{
                                p{
                                    font-size: clamp(0.5em, 3.5vw, 1em);
                                }
                                img{
                                    height: 19vw;
                                }
                            
                            }
                            &.content{
                                flex-basis: 41%;
                                height: 10rem;
                                margin: 0.5rem;
                            }
                        } 
                    }
                    .topic-col{
                        width: 100%;
                        padding: 0;
                        p{
                            font-size: clamp(0.5em, 5.5vw, 2em);
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &:first-child{
                        margin: 0;
                    }
                }
            }
            .button{
                font-size: clamp(0.8em, 1vw, 1em);
                &.try-out{
                    font-size: clamp(0.8em, 4.6vw, 2em);
                    margin: 0;
                    margin-top: 2rem;
                    padding: 0.75rem 1rem;
                }
            }
        }
        h3{
            margin-bottom: 0;
            font-size: clamp(1em, 5.7vw, 4em);
        }
        .w-60{
            width: 100% !important;
        }
        .social-media-row{
            flex-wrap: wrap;
            justify-content: center;
            background: none;
            padding: 0;
            box-shadow: none;
            margin-top: 2rem;
            padding: 0 2rem;
            &::before{
                display: none;
            }
        }
        .media-container{
            width: 90px;
            margin: 0.5rem 0;
            flex-basis: 33%;
            p{
                color: #A9B9EE;
                font-size: clamp(0.5em, 4.2vw, 2em);
            }
        }
        .topic-title{
            display: flex;
            padding: 0 2.25rem;
            &.short{
                display: flex;
                width: 80%;
                margin-bottom: 1rem;
                h3{
                    text-align: left;
                }
            }
        }
        .try-screen{
            margin-top: 35%;
            position: absolute;
        }
        .logo{
            width: 60%;
            margin: auto;
    
        }
    }
    @keyframes arrow-animation{
        0%{
            opacity: 0;
        }
        20%{
            opacity: 1;
        }
        80%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }

</style>
<style lang="scss">
.logo{
    svg{
        width: 100%;
    }
}
@media (max-width: 768px){
    h2{
        span{
            font-size: clamp(16px, 7vw, 32px);
        }
    }
}
</style>
