import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import VTooltip from 'v-tooltip'
import Vuelidate from 'vuelidate'
import VueGtag from "vue-gtag";
import translationLT from '@/languages/lt'
import translationEN from '@/languages/en'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VTooltip)
Vue.use(VueCookies)
Vue.use(VueScrollTo)
Vue.use(VueLodash , { name: 'custom' , lodash: lodash })

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-4LYBW45S0Q" }
});
Vue.use(Vuex)
Vue.$cookies.config('1d')
if (window.language == 'lt') {
  Vue.prototype.$translation = translationLT;
  // document.title = 'eLKlasė'
}
else {
  Vue.prototype.$translation = translationEN;
  // document.title = 'LKclass'
}
const store = new Vuex.Store({
  state: {
    windowWidth: 0,
  },
 
})

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
