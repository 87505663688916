<template>
  <div :class="['terms']" v-scroll-lock="true">
    <div class="terms--inner">
      <div v-html="$translation.termsAndConditions" />
    </div>
    <div class="close" @click="$emit('closeModal')">
        <span></span><span></span>
    </div>
  </div>
</template>

<script>
export default {    
    name: 'PrivacyPolicy',
}
</script>

<style lang="scss" scoped>
    .terms {
        position: fixed;
        z-index: 9999;
        top: 0; 
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        overflow-y: scroll;
        transition: .5s left, .5s opacity;

        &.hidden {
          left: -110vw;
          opacity: 0;
        }
    }
    .terms--inner {
        width: 90%;
        max-width: 1150px;
        margin-top: 7rem;
        margin: auto;
        text-align: left;
    }
    h2 {
        font-size: 45px;
        font-weight: bold;
        color: #28345c;

        @media (max-width: 768px) {
            font-size: 25px;
        }
    }
    h3 {
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    p {
        text-align: justify;

        @media (max-width: 768px) {
            font-size: 17px;
        }
    }
    .close{
        display: flex;
        position: fixed;
        right: 2rem;
        top: 1rem;
        background: white;
        color: #28345C;
        box-shadow: 0 0 9px -1px #bbb;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        span{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 4px;
            height: 2rem;
            border-radius: 1rem;
            background: #28345C;
            transform: rotate(45deg);
        }
        span:last-child{
            transform: rotate(-45deg);
        }
        @media (max-width: 768px){
            width: 2rem;
            height: 2rem;
            right: 1rem;
            span{
                height: 1.25rem;
            }
        }
    }
</style>