<template>
<div class="footer-container">
  <div class="footer-row parent">
      <div class="footer-col long parent">
          <h2>
              {{$translation.footer_contacts}}
          </h2>
            <div class="footer-row">
                <div class="footer-col col-left">
                    <h3>
                        {{$translation.footer_developer}}
                    </h3>
                    <span>{{$translation.footer_developer_UAB}}</span>
                    <span>{{$translation.footer_developer_site}}</span>
                    <span>{{$translation.footer_developer_ik}}</span>
                    <span>{{$translation.footer_developer_pvm}}</span>
                    <span>{{$translation.footer_developer_address}}</span>
                </div>
                <div class="footer-col">
                    <h3>
                        {{$translation.footer_distributor}}
                    </h3>
                    <span>{{$translation.footer_distributor_name}} </span>
                    <span>{{$translation.footer_distributor_uab}}</span>
                    <span>{{$translation.footer_distributor_address}}</span>
                    <span>{{$translation.footer_distributor_phone}} </span>
                    <span>{{$translation.footer_distributor_mobile_phone}} </span>
                    <span>{{$translation.footer_distributor_email}}</span>
                </div>
            </div>
      </div>
      <div class="footer-col short parent">
          <h2>
              {{$translation.footer_help}}
          </h2>
           <div class="footer-col">
               <div class="icon-container" @click="openHelp">
                   <span>{{$translation.footer_help_centre}}</span>
                   <div class="icon">
                       <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
                        <g id="Group_8994" data-name="Group 8994" transform="translate(-1460 -7582)">
                            <g id="Ellipse_672" data-name="Ellipse 672" transform="translate(1460 7582)" fill="#fff" stroke="#a9b9ee" stroke-width="3">
                            <circle cx="23" cy="23" r="23" stroke="none"/>
                            <circle cx="23" cy="23" r="21.5" fill="none"/>
                            </g>
                            <g id="Group_8233" data-name="Group 8233" transform="translate(1479.815 7590.492)">
                            <ellipse id="Ellipse_673" data-name="Ellipse 673" cx="3" cy="3.5" rx="3" ry="3.5" transform="translate(0.185 -0.492)" fill="#a9b9ee"/>
                            <rect id="Rectangle_2863" data-name="Rectangle 2863" width="6" height="20" rx="3" transform="translate(0.185 9.508)" fill="#a9b9ee"/>
                            </g>
                        </g>
                        </svg>
                   </div>
               </div>
               <span>
                  {{$translation.footer_help_email}}
               </span>
           </div>
      </div>
  </div>
  <div class="footer-row privacy">
      <div class="privacy-row">
          <span style="cursor:pointer" @click="$emit('privacyClicked')">{{$translation.footer_privacy_policy}}</span>
          <span>{{$translation.footer_copyright.replace('[year]', getCurrentYear())}}</span>
      </div>
  </div>
  <GradientWheel v-if="$store.state.windowWidth <= 768" class="footer-gradient"/>
</div>
</template>

<script>
import GradientWheel from '@/components/GradientWheel.vue'

export default {
    components:{
        GradientWheel
    },
    methods:{
        openHelp(){
            window.open("https://elklase.tawk.help", '_blank').focus();
        },
        getCurrentYear(){
            return new Date().getFullYear();
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-container{
    .footer-row{
        display: flex;
        width: 100%;
        margin: auto;
        .long{
            width: 100%;
        }
        .short{
            width: 40%;
        }
        &.eighty{
            width: 80%;
        }
        &.privacy{
            width: 100%;
            position: absolute;
            bottom: 1rem;
            margin: 0 auto;
            justify-content: center;
            left: 0;
        }
    }
    .footer-col{
        display: flex;
        flex-direction: column;
        width: 100%;
        &.parent{
            margin: 0 5rem;
        }
        span{
            justify-content: flex-start;
            text-align: left;
            display: inline-flex;
            white-space: nowrap;
            line-height: 1.5;
        }
    }
}
h2{
    display: inline-flex;
    margin: 1rem 0;
    padding: 0;
    border-bottom: 0.35rem solid #A9B9EE;
    padding-bottom: 0.75rem;
    font-weight: 900;
    width: 100%;
    justify-content: center;
}
h3{
    display: flex;
    font-weight: 500;
}
.icon-container{
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon{
        margin: 0 0.5rem;
        svg{
            height: 2rem;
            width: 2rem;
        }
        svg  > *{
            transition: all .3s;
        }
    }
    &:hover{
        svg{
            ellipse, rect{
                fill: #28345C
            }
            circle:last-child{
                stroke: #28345C
            }
        }
    }
}
.privacy-row{
    span{
        margin: 0 1rem;
    }
}
.col-left{
    margin-right: 6rem;
}
.footer-gradient{
    position: absolute;
    bottom: -23%;
}
@media (max-width: 768px){
    .footer-container{
        display: flex;
        flex-direction: column;
        width: 100%;
         .footer-row{
            flex-direction: column;

         }
        .footer-row.parent{
            flex-direction: column;
            padding: 0 2rem;
        }
        .footer-col{
            &.parent{
                margin: 0;
            }
            &.short.parent{
                margin-top: 5rem;
                margin-bottom: 15rem;
            }
            span{
                line-height: 1.5;
            }
        }
    }
    h3{
        font-weight: 900;
    }
    .icon-container{
        margin-bottom: 1rem;
    }
    .privacy-row{
        display: flex;
        flex-direction: column;
        line-height: 1.5;
    }
}
</style>