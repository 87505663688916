<template>
  <div class="modal-container">
    <div class="row" v-click-outside="hide">
        <div class="col left">
            <span class="input-label">  {{$translation.card_register_email}}</span>
            <div class="input-container">
                <input type="email" v-model="email" :placeholder="$translation.card_register_email">
            </div>
            <div class="button md full-width m-1" @click="resetPassword">
                {{$translation.reset_password_btn}}
            </div>
        </div>
        <div class="col">
            <svg xmlns="http://www.w3.org/2000/svg" width="210.386" height="222.214" viewBox="0 0 210.386 222.214">
            <g id="Artwork_2" data-name="Artwork 2" transform="translate(105.193 111.107) rotate(30)">
                <g id="Group_4" data-name="Group 4" transform="translate(-71.093 -87.25)">
                <path id="Path_41" data-name="Path 41" d="M122.8,64.953v-18.1C122.8,21,99.53,0,71.093,0s-51.7,21-51.7,46.856v18.1A23.267,23.267,0,0,0,0,87.9v63.337A23.267,23.267,0,0,0,23.267,174.5h95.652a23.267,23.267,0,0,0,23.267-23.267V87.9a23.267,23.267,0,0,0-19.391-22.944Zm-96.944-18.1c0-22.3,20.358-40.394,45.241-40.394s45.241,18.1,45.241,40.394V64.63H25.852Zm109.87,104.377a16.8,16.8,0,0,1-16.8,16.8H23.267a16.8,16.8,0,0,1-16.8-16.8V87.9a16.8,16.8,0,0,1,16.8-16.8h95.652a16.8,16.8,0,0,1,16.8,16.8Z"/>
                <path id="Path_42" data-name="Path 42" d="M30.926,28A12.926,12.926,0,0,0,18,40.926V60.315a12.926,12.926,0,0,0,25.852,0V40.926A12.926,12.926,0,0,0,30.926,28Zm6.463,32.315a6.463,6.463,0,0,1-12.926,0V40.926a6.463,6.463,0,1,1,12.926,0Z" transform="translate(40.167 62.481)"/>
                </g>
            </g>
            </svg>

        </div>
    </div>
    <div class="row" v-if="message != ''">
        {{message}}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ClickOutside from 'vue-click-outside'

export default {
    name: 'ForgotPasswordModal',
    data(){
        return{
            email: '',
            message: '',
            modalIsOpen: false,
        }
    },
    mounted(){
        setTimeout(()=>{
            this.modalIsOpen = true;
        },100)
    },
    methods:{
        resetPassword(){
            this.message = '';
            if(this.email == '') return;
            axios.post('https://api.dev.learnkey.lt/api/forgot/password/any',
            {
                email: this.email,
            })
            .then((res)=>{
                this.message = res.data.message;
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        hide(){
            if(this.modalIsOpen){
                this.$emit('closeModal');
            }
        }
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_inputs.scss";
@import "@/assets/scss/_button.scss";
.modal-container{
    position: absolute !important;
    padding: 2rem;
    max-width: 33rem;
    width: 30vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 30%;
    right: 106%;
    background: #c8d2f5;
    border-radius: 1vw;
    box-shadow: 0px 3px 8px 1px #acb5d2f0;
    &::before{
        position: absolute;
        content: "";
        left: -4px;
        top: -4px;
        border-radius: 1.5rem;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        background: #bcc9f524;
        z-index: -1;
    }
    @media (max-width: 768px){
        right: 0;
        left: 0;
        border-radius: 1rem;
        .col:last-child{
            display: none;
        }
        .col.left{
            width: 100%;
        }
    }
}
.row{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.col{
    display: flex;
    flex-direction: column;
    width: 100%;
    width: 45%;
    &.left{
        width: 55%;
    }
}
svg{
    path{
        fill: #28345C;
    }
    width: 100%;
    height: 9vw;
    padding-left: 1rem;
    max-height: 10rem;
}
.m-1{
    margin: 1rem 0 !important;
}
.input-label{
    margin: 0.25rem;
}

</style>