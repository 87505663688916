<template>
    <transition name="fade" mode="in-out">
    <div class="return-to-top-container" v-if="$store.state.windowWidth <= 768 ? showIcon : currentTopic != 0" @click="scrollEvent">
        <svg xmlns="http://www.w3.org/2000/svg" width="35.103" height="39.115" viewBox="0 0 35.103 39.115">
            <g id="Group_8390" data-name="Group 8390" transform="translate(3 4.243)">
                <path id="Line_117" data-name="Line 117" d="M0,32.276a3,3,0,0,1-3-3V0A3,3,0,0,1,0-3,3,3,0,0,1,3,0V29.276A3,3,0,0,1,0,32.276Z" transform="translate(14.656 2.596)" fill="#fff"/>
                <path id="Path_8184" data-name="Path 8184" d="M-2829.6,6284.4a2.99,2.99,0,0,1-2.121-.879l-12.43-12.43-12.43,12.43a3,3,0,0,1-4.243,0,3,3,0,0,1,0-4.243l16.673-16.673,16.672,16.673a3,3,0,0,1,0,4.243A2.992,2.992,0,0,1-2829.6,6284.4Z" transform="translate(2858.698 -6266.853)" fill="#fff"/>
            </g>
            </svg>

    </div>
    </transition>
</template>

<script>
export default {
    name: 'GotToTop',
    data(){
        return{
            posFromTop: 0,
            main_wrapper_scroll: 0,
            main_wrapper_scroll_elem: 0,
            showIcon: false,
        }
    },
    props:{
        currentTopic:{
            type: Number,
        }
    },
    mounted(){
        this.posFromTop = window.scrollY;
        if(this.$store.state.windowWidth <= 768){
            this.main_wrapper_scroll_elem = this.$parent.$el.getElementsByClassName('main-page-wrapper')[0];
            this.main_wrapper_scroll_elem.addEventListener("scroll", this.calcPosFromTop);
        }
    },
    beforeDestroy(){
        if(this.$store.state.windowWidth <= 768){
            this.main_wrapper_scroll_elem.removeEventListener("scroll", this.calcPosFromTop);
        }
    },

    methods:{
        scrollEvent(){
            this.$emit('scrollToTop');
        },
        calcPosFromTop(elem){
            let target = elem.target;
            if(this.$store.state.windowWidth <= 768){
                this.showIcon = target.scrollTop >= window.innerHeight;
            }else{
                this.showIcon = this.currentTopic != 0;
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.return-to-top-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    background: #A9B9EE;
    transition: background 0.3s;
    cursor: pointer;
    z-index: 99;
    &:hover{
        background: #28345C;
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
@media (max-width: 768px){
    .return-to-top-container{
        bottom: 1rem;
        right: 1rem;
    }
}
</style>