<template>
  <div class="wheel-container">
      <div class="parent-container container">
          <div class="parent el">
              <div class="child-container container">
                  <div class="child el">
                    <div class="grand-child-container container">
                        <div class="grand-child el">

                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div class="gradient-container">

      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.gradient-container{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
     background: linear-gradient(0deg, rgba(255,255,255,1) 47%, rgba(255,255,255,0) 53%);
}
.wheel-container{
     background: linear-gradient(0deg, rgba(255,255,255,1) 47%, rgba(255,255,255,0) 53%);
     position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top:40%;
    z-index: -2;
    .container{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70vw;
        height: 70vw;
    }
    .parent-container{
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-top: 12%;
        z-index: -2;
    }
    .el{
        position: relative;
        background-color: #E9EDFB;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: -1;
    }
    .child{
        border-radius: 50%;
        width: 50vw;
        height: 50vw;
        background-color: #D9E0F8;
    }
    .grand-child-container{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .grand-child{
        border-radius: 50%;
        width: 30vw;
        height: 30vw;
        background-color: #CDD6F6;
    }
}
@media (max-width: 768px){
    .wheel-container{
        // top: 60%;
        margin-top: 100%;
        .container{
            width: 120vw;
            height: 120vw;
        }
        .parent-container{
            left: -10vw;
        }
        .child{
            width: 90vw;
            height:90vw;
        }
        .grand-child-container{
            width: 100%;
            height: 80vw;
        }
        .grand-child{
            width: 50vw;
            height: 50vw;
        }
        .gradient-container{
            background: linear-gradient(0deg, white 47%, rgba(255, 255, 255, 0) 60%);
        }
    }
}
</style>