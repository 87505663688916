<template>
  <div class="scrollbar-container" @click="scrollToTopic" >
      <div 
            :style="{top: fromTop+'%'}"
            :class="{transitioning: thumbTransitioning}"
            class="scrollbar-thumb" 
            @mousedown="isPressed = true;" 
        >

      </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
    data(){
        return{
            isPressed: false,
            mouseCoords: [],
            direction: 0,
            fromTop: 0,
            lastPosition: 0,
            thumbTransitioning: false,
        }
    },
    props:{
        currentTopic: {
            type: Number,
        },
        isTransitioning: {
            type: Boolean,
        },
    },
    watch:{
        'currentTopic'(newVal,){
            if(!this.isPressed){
                //let oldTop = 100/7*oldVal;
                let newTop = 100/7*newVal;
                // let direction = newVal - oldVal;
                // console.log(parseInt(oldTop-newTop));
                // if(direction <0 ){
                //     for(let i = 0; i < oldTop-newTop; i++){
                //         this.fromTop = oldTop - i;
                //     }

                // }
                this.thumbTransitioning = true;
                this.fromTop = newTop;
                setTimeout(()=>{
                    this.thumbTransitioning = false;
                },300)
                // this.fromTop = 100/7*newVal;
            }

        },
        fromTop: function(newVal){
            if(this.isPressed){
                let topic = parseInt(newVal/100 * 8);
                if(this.lastPosition != topic){
                    this.sendCurrentTopic(this, topic);
                }
            }   
        }
    },
    mounted(){
        window.addEventListener('mouseup', this.mouseUp);
        window.addEventListener('mousemove', this.mouseMove);
    },
    beforeDestroy(){
        window.removeEventListener('mouseup', this.mouseUp);
        window.removeEventListener('mousemove', this.mouseMove);
    },
    methods:{
        mouseUp(){
            if(this.isPressed){
                this.isPressed = false;
            }
        },
        mouseMove(e){
            if(this.isPressed){
                let scroll_elem = document.getElementsByClassName('scrollbar-container')[0];
                let scroll_thumb_elem = document.getElementsByClassName('scrollbar-thumb')[0];
 
                let temp = ((e.clientY-scroll_elem.offsetTop- (scroll_thumb_elem.offsetHeight/2))/ scroll_elem.offsetHeight) * 100
                let thumb_percent = (scroll_thumb_elem.offsetHeight) / scroll_elem.offsetHeight * 100;
                if(temp < 0){
                    temp = 0;
                }
                if(temp > 100-thumb_percent){
                    temp = 100-thumb_percent;
                }
                this.fromTop = temp;
            }
        },
        scrollToTopic(e){
            if(!e.target.classList.contains('scrollbar-thumb')){
                let rect = e.target.getBoundingClientRect();
                let y = e.clientY - rect.top;
                let element_height = e.target.clientHeight;
                let topic = parseInt(Math.round(y / element_height * 7));
              
                if(topic > 6)
                    topic = 6;
                if(!this.isTransitioning){
                    this.$emit('jumpTopic', topic);
                }
            }
        },
        sendCurrentTopic: _.debounce((self, topic)=>{
            self.$emit('jumpTopic', topic);
            self.lastPosition = topic;
        },70)
    }
}
</script>

<style lang="scss" scoped>
.scrollbar-container{
    position: fixed;
    right: 0;
    width: 1rem;
    height: 99vh;
    border-radius: 2rem;
    background: white;
    margin: auto;
    top: 0;
    bottom: 0;
    // transition: top 0.5s;
    overflow: hidden;
    box-shadow: 0px 0px 12px -4px #28345c;
}
.scrollbar-thumb{
    position: relative;
    background: #A9B9EE;
    height: calc(100% / 6);
    border-radius: 2rem;
    // transition: top 0.5s;
    cursor: pointer;
    user-select: none;
    &::after{
        position: relative;
        display: flex;
        content: '';
        background: radial-gradient(circle, #cad6ff 52%, white 94%);
        top: -200%;
        left: 0;
        bottom: 0;
        margin: auto;
        height: 500%;
        z-index: -1;
        width: 100%;
    }
    &.transitioning{
        transition: top 0.3s;
    }
}
</style>