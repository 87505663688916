<template>
  <div>
      <canvas id="bubble-background"></canvas>
  </div>
</template>

<script>
export default {
        data(){
        return{
            configKonva: {
                width: 200,
                height: 200
            },
            configCircle: {
                x: 100,
                y: 100,
                radius: 70,
                fill: "red",
                stroke: "black",
                strokeWidth: 4
            },
            canvas: null,
            ctx: null,
            bubbles: [],
            bubbleCount: 8,
            color: "#D3DBF6",
            mouse:{
                x: null,
                y: null
            },
            bubbleSizes: [100, 60 , 40],
            currentBubbleCount: 0,
            addingBubblesInterval: null,
            bubble_svg: this.iconUrl,
        }
    },
    watch:{
        currentBubbleCount(newVal){
            if(newVal == this.bubbleCount){
                clearInterval(this.addingBubblesInterval);
            }
        },
        '$store.state.windowWidth':{
            immediate:true,
            handler(newVal){
               this.bubbleSizes = newVal >= 768 ? [100, 60, 40] : [60, 40, 20]; 
            }
        }
    },
    mounted(){
        this.canvas = document.getElementById('bubble-background');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        var img = new Image();
        img.src = this.iconUrl;
        this.bubble_svg = img;

        let self = this;
        // window.addEventListener("mousemove", function(e) {
        //     self.mouse.x = e.offsetX;
        //     self.mouse.y = e.offsetY;
        // });
        window.addEventListener("resize", function() {
            self.canvas.width = window.innerWidth;
            self.canvas.height = window.innerHeight;
        });
        this.addingBubblesInterval = setInterval(function(){
            self.bubbles.push(self.newBubble());
            self.currentBubbleCount += 1;
        }, 500);
        
        this.animate();
    },
    beforeDestroy(){
        clearInterval(this.addingBubblesInterval);
    },
    computed:{
        iconUrl () {
            return require('../assets/bubble.png')
            // The path could be '../assets/img.png', etc., which depends on where your vue file is
        }
    },
    methods:{
        draw(bubble){
            this.ctx.drawImage(this.bubble_svg, bubble.x, bubble.y, bubble.width, bubble.height);
        
            this.updateBubble(bubble);
        },
        updateBubble(bubble){
            if(bubble.direction == 1 && bubble.dx >  Math.PI){
                bubble.direction = 0;
            }
            else if(bubble.direction == 0 && bubble.dx < -Math.PI){
                bubble.direction = 1;
            }
            bubble.x += Math.cos(bubble.dx)/3;
            bubble.y += bubble.dy;
            bubble.dx = bubble.direction  == 1 ? bubble.dx + 0.05 : bubble.dx - 0.05;

            if (bubble.y + bubble.width < 0) {
                let index = this.bubbles.indexOf(bubble);
                this.bubbles[index] = this.newBubble(this.bubbles[index].id);
                // let new_bubble_id = Math.max(0, this.bubbles.map(el => el.id)) + 1;
                // this.newBubble(new_bubble_id);
            }
            // interactivity
            // if (
            //     this.mouse.x - bubble.x < 80 &&
            //     this.mouse.x - bubble.x > -80 &&
            //     this.mouse.y - bubble.y < 80 &&
            //     this.mouse.y - bubble.y > -80 &&
            //     bubble.width < bubble.maxWidth
            // ) {
            //     bubble.width += 1;
            //     bubble.x -= 1;
            //     bubble.y -= 1;
            // } else if (bubble.width > bubble.minWidth) {
            //     bubble.width -= 1;
            //     bubble.x += 1;
            //     bubble.y += 1;
            // }
        },
        createBubbles(){
            for(let i = 0; i < this.bubbleCount; i++){
               this.bubbles.push(this.newBubble(i));
            }
        },
        newBubble(id){
            let width_index = this.getRandomInt(this.bubbleSizes.length);
            let width = this.bubbleSizes[width_index];
            let x = Math.random() * window.innerWidth;
            let dx = 0;
            let y =  Math.random() *  window.innerHeight/2 + window.innerHeight;
            let dy = Math.min(width, ...this.bubbleSizes) == width ? -0.8 : -0.5; 
            let bubble = {
                id: id,
                x: x,
                y: y,
                dx: dx,
                dy: dy,
                width: width,
                height: width,
                minWidth: width,
                maxWidth: width * 3,
                direction: id % 2 == 0 ? 1 : 0,
                // color: '#' + Math.floor(Math.random() * 16777215).toString(16)
            }
            return bubble;
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
            this.bubbles.forEach(bubble => {
                this.draw(bubble);
            });
        },
        getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }

    }
}
</script>

<style lang="scss" scoped>
 #bubble-background{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: -1;
        left: 0;
        top:0;
    }
</style>